<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/admin/Customer/create" class="btn btn-primary">新增</router-link>
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.id">
									<td>{{item.name}}</td>
									<td>{{item.idcard}}</td>
									<td>{{item.sex}}</td>
									<td>{{item.phone}}</td>
									<td>{{item.email}}</td>
									<td>{{item.address}}</td>
									<td>{{item.representative}}</td>
									<td>{{item.rpt_sex}}</td>
									<td>{{item.rpt_idcard}}</td>
									<td>{{item.rpt_phone}}</td>
									<td>{{item.rpt_email}}</td>
									<td>{{item.rpt_addr}}</td>

									<td>
										<router-link :to="`/admin/Customer/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
												刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" @PageSizeCallback="onPageSizeChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
					<div class="col-auto">
						<label class="control-label">設置者</label>
						<input v-model="filter.name" id="name" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">聯絡電話</label>
						<input v-model="filter.phone" id="phone" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">設籍地址</label>
						<input v-model="filter.address" id="address" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">負責人姓名</label>
						<input v-model="filter.representative" id="representative" class="form-control" />
					</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listCustomer, deleteCustomer } from '@/api/Customer.js'


export default {
	name: 'Customerlist',
	extends: baseList,
	data() {
		return {
			filter: {
					name: "",
					phone: "",
					address: "",
					representative: "",

			},
			pagination: {
				sortItem: "id",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{id: 'name', label: "設置者", icon: ""},
				{id: 'idcard', label: "統邊/身份證字號", icon: ""},
				{id: 'sex', label: "姓別", icon: ""},
				{id: 'phone', label: "聯絡電話", icon: ""},
				{id: 'email', label: "電子信箱", icon: ""},
				{id: 'address', label: "設籍地址", icon: ""},
				{id: 'representative', label: "負責人姓名", icon: ""},
				{id: 'rpt_sex', label: "負責人性別", icon: ""},
				{id: 'rpt_idcard', label: "負責人身份證", icon: ""},
				{id: 'rpt_phone', label: "負責人電話", icon: ""},
				{id: 'rpt_email', label: "負責人信箱", icon: ""},
				{id: 'rpt_addr', label: "負責人地址", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {
			this.filter.name = "";
			this.filter.phone = "";
			this.filter.address = "";
			this.filter.representative = "";

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listCustomer(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;

				$("#filterModal").modal('hide');
			});
		},
		deleteRecord(id) {
			return deleteCustomer(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
